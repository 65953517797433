<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="NBdriedfruitsCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import NBapricot from "../assets/NUTBERRY/NBdf/NB-apricot-190.jpg";
import NBprunes from "../assets/NUTBERRY/NBdf/NB-prune-280.jpg";
import NBraisinW from "../assets/NUTBERRY/NBdf/NB-raisinW-190.jpg";
import NBraisinB from "../assets/NUTBERRY/NBdf/NB-raisinB-190.jpg";
import NBraisinC from "../assets/NUTBERRY/NBdf/NB-raisinC-190.jpg";
import NBapricotCuraga from "../assets/NUTBERRY/NBdf/NB-curaga-190.jpg";

export default {
  name: "NutberryDriedfruits",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Сухофрукты и сушёные ягоды",
      NBdriedfruitsCards: [
        {
          picture: NBapricot,
          text: "NUTBERRY Абрикос 190 г",
          id: 1,
          description:
            "Состав: абрикосы сушеные без косточек, консервант Е220.<br>Продукт может содержать косточки или их фрагменты; следы арахиса, кунжута, орехов. Продукт   рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки 5,5 г, Жиры 0,5 г, Углеводы 63,5 <br>Энергетическая ценность 291 Ккал.<br>Хранить при температуре от +5 °С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBprunes,
          text: "NUTBERRY Чернослив 280 г",
          id: 2,
          description:
            "Состав: сливы сушеные без косточки, консервант Е202, масло подсолнечное.<br>Продукт может содержать косточки либо фрагменты косточек, следы арахиса, кунжута и орехов. Рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки 1,9 г, Жиры 0,5 г, Углеводы 59,5 г<br>Энергетическая ценность 256 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBraisinW,
          text: "NUTBERRY Изюм светлый 190 г",
          id: 3,
          description:
            "Состав: виноград без косточек сушеный, масло растительное, консервант Е220.<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 2,5 г, Жиры 0,2 г, Углеводы 63,5 г, Пищевые волокна 9 г<br>Энергетическая ценность 321 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBraisinB,
          text: "NUTBERRY Изюм темный 190 г",
          id: 4,
          description:
            "Состав: изюм (виноград сушеный без косточек, масло подсолнечное).<br>Продукт может содержать следы арахиса, кунжута, орехов. Продукт рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки - 2,9 г, Жиры 0,1 г, Углеводы 74,7<br>Энергетическая ценность 311 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBraisinC,
          text: "NUTBERRY Изюм кишмиш 190 г",
          id: 5,
          description:
            "Состав: виноград сушеный без косточек, масло подсолнечное, консервант Е220.<br>Продукт может содержать следы арахиса, кунжута, орехов. Продукт рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки - 2,5 г, Жиры 0,2 г, Углеводы 71,0<br>Энергетическая ценность 296 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBapricotCuraga,
          text: "NUTBERRY Курага 190 г",
          id: 6,
          description:
            "Состав: абрикосы сушеные без косточек, консервант Е220.<br>Продукт может содержать косточки или их фрагменты; следы арахиса, кунжута, орехов. Продукт   рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки 5,5 г, Жиры 0,5 г, Углеводы 63,5 <br>Энергетическая ценность 291 Ккал.<br>Хранить при температуре от +5 °С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
